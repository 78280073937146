import React from 'react';
import * as Sentry from '@sentry/react';
import { BroadcastChannel } from 'broadcast-channel';
import { handleLogoutAndRemoveRefreshToken } from '../services/fetchService';
import { studentLocalStorageKeys } from './sitedata/studentLocalStorageKeys';

const logoutChannel = new BroadcastChannel('logout');

export const generateUniqueId = () => 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
  // eslint-disable-next-line no-bitwise
  const r = Math.random() * 16 | 0; const
    // eslint-disable-next-line no-bitwise, eqeqeq, no-mixed-operators
    v = c == 'x' ? r : (r & 0x3 | 0x8);
  return v.toString(16);
});

export const refreshPage = () => {
  // Don't do complete relead, just do cache version of browser reload...
  window.location.reload();
};

// the timeout value for api calls throughout our app!
export const getTimeValue = (name: string) => {
  // on our test site we allow longer timeouts
  if (process.env.NODE_ENV === 'development') {
    return 15000;
  }

  // TODO: very dangerous, because simply changing the api call name will cause this code to not
  //  work anymore. the user editing the names of these api calls won't know this is here!
  // these two api calls might take longer then the rest, because these includes creating order
  // in paypal and processing card for payment which might take some time, that's why we are
  // increasing the time here.
  if (name === 'CreateOrderForPurchaseMutation' || name === 'CapturePaymentMutation') {
    return 10000;
  }

  // getting user's remaining credits is a slow php operation
  if (name === 'HoursReaminingMutation') {
    return 8000;
  }

  // default api call timeout
  return 5000;
};

// TODO: we should probably be using removeJWTFromLocalStorageAndLogoutUser here, so we have one
//  single logout function not two
// this is responsible for resetting the legacy app cookies and once get success response 
// from resetting the cookies, remove the JWT and redirect user back to login.
export const handleLogout = async (setLogoutInProgress: React.Dispatch<
    React.SetStateAction<boolean>>) => {
  logoutChannel.postMessage('Logout');
  try {
    // this will set the authContext state which later on we are accessing in dashboard layout
    // and displaying backdrop based on it.
    setLogoutInProgress(true);
    await handleLogoutAndRemoveRefreshToken();
  } catch (err: any) {
    // Can check on sentry, why logOut not working...
    Sentry.captureException(err);
  } finally {
    localStorage.removeItem(studentLocalStorageKeys.studentSecurity);
    setLogoutInProgress(false);
    window.location.replace(`${process.env.REACT_APP_LEGACYURL}/studenthome/`);
  }
};

/*
  When a user logs out, they should be logged out in all tabs they have open. 
  We've used broadcase channel APIs and listening to all messages, once from any tab, 
  user do logout, we post a logout message which we can receive here in our logoutAllTabs
  this calls the logout again in all opened tabs and close that channel.  */
export const logoutAllTabs = (setLogoutInProgress: React.Dispatch<
    React.SetStateAction<boolean>>) => {
  logoutChannel.onmessage = (message) => {
    if (message === 'Logout') {
      handleLogout(setLogoutInProgress);
      logoutChannel.close();
    }
  };
};
