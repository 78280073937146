import React from 'react';
import { useLazyLoadQuery } from 'react-relay';
import { GetUserPreferencesQuery } from './relay/GetUserPreferences';
import { AIChatPartner } from './AIChatPartner1';
import { AIChatPartnerProvider } from './context/AIChatPartnerProvider';

// this component is responsible for executing the query
export const AIChatPartnerLoadQuery = () => {
  // here our query will execute...
  const response: any = useLazyLoadQuery(
    GetUserPreferencesQuery,
    {},
  );

  // extracting the fragmentRef so we can read data.
  const fragmentRefForUserPrefrences = response.user_settings_connection.edges[0].node;

  // extracting fragmentRef for langLearning 
  const fragmentRefForLngLearning = response.users_connection.edges[0].node;

  return (
    // wrapping `AIChatPartner` with provider so we can access the states every weher 
    // in the child component  
    <AIChatPartnerProvider>
      <AIChatPartner
        fragmentRefForUserPrefrences={fragmentRefForUserPrefrences}
        fragmentRefForLngLearning={fragmentRefForLngLearning}
      />
    </AIChatPartnerProvider>
  );
};
