import React from 'react';
import {
  Button, Dialog, Grid2, Grid2Ct, RadioGroup, Ty
} from '@languageconvo/wcl';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFragment } from 'react-relay';
import { SkillLevel } from '../../schema/SkillLevel';
import { GetUserPreferencesFragment$key } from '../../relay/__generated__/GetUserPreferencesFragment.graphql';
import { GetUserPreferencesFragment } from '../../relay/GetUserPreferences';
import { useSaveStdPreferences } from '../../hooks/useSaveStdPreferences';
import useAIChatPartner from '../../hooks/useAIChatPartner';

interface Props {
    fragmentRefForUserPrefrences: GetUserPreferencesFragment$key;
}

export const ChangeSkillLevelDialog = ({
  fragmentRefForUserPrefrences,
}: Props) => {
  // reading data from relay store via fragment, we need the data
  const userPreferences = useFragment(GetUserPreferencesFragment, fragmentRefForUserPrefrences);

  const { openSkillModal, setOpenSkillModal, isMutationError } = useAIChatPartner();

  // we have used react-hook-form here so we don't have to manually create the state and manage
  // that state, we pass in the default schema for validations and default values data we want
  // to render on the screen
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({
    reValidateMode: 'onChange',
    resolver: yupResolver(SkillLevel),
  });

  // #region for radio option

  // our options array for skill level
  const skillLevelOption = [
    { label: 'Beginner', value: 1 },
    { label: 'Intermediate', value: 2 },
    { label: 'Advanced', value: 3 },
  ];

  // #endregion

  /**
   * there are 3 places from where we are getting user preferences 
   * instead of duplicating same code, we created a hook, which is mutating data
   * from single place and exposing different states, i-e laoding, mutate...
   */
  const { saveStdPreference, loading } = useSaveStdPreferences();

  const saveSkillLevel = (data: any) => {
    // passing user selected data to the custom hook, so we can store the user preference
    // to our db for future use,
    saveStdPreference(userPreferences.aic_translate, data.skill_level);
  };

  return (
    <Dialog
      isOpen={openSkillModal}
      onClose={() => setOpenSkillModal(!openSkillModal)}
      width="xs"
      color="accentGreen1"
    >
      <Grid2Ct>
        {/* radio */}
        <Grid2 xs={12}>
          <Controller
            name="skill_level"
            control={control}
            render={({ field: { onChange, value } }) => (
              <RadioGroup
                options={skillLevelOption}
                value={value}
                onChange={onChange}
                defaultValue={userPreferences.aic_level}
              />
            )}
          />
        </Grid2>

        {/* note start new conversation */}
        <Grid2 xs={12}>
          <Ty>
            <strong>Note</strong>: changing this will start a new conversation
          </Ty>
        </Grid2>

        {/* no option selected alert */}
        {Object.keys(errors).length > 0 && (
        <Grid2 xs={12} sx={{ mt: 1 }}>
          <Ty>
            Oops! It looks like you forgot to select an option above.
          </Ty>
        </Grid2>
        )}

        {/* mutation error alert */}
        {isMutationError && (
        <Grid2 xs={12} sx={{ mt: 1 }}>
          <Ty>
            Oops! An error occurred. Please choose another option and try again.
          </Ty>
        </Grid2>
        )}

        {/* save button */}
        <Grid2 xs={12} sx={{ mt: 1 }}>
          <Button
            color="accentGreen1"
            disabled={loading}
            isLoading={loading}
            onClick={handleSubmit(saveSkillLevel)}
          >
            Save Change
          </Button>
        </Grid2>
      </Grid2Ct>
    </Dialog>
  );
};
