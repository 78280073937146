import React, { Suspense } from 'react';
import { AIChatPartnerLoadQuery } from './AIChatPartner0';

export const AIChatPartnerContainer = () => {
  // eslint-disable-next-line
  const x = 1;

  return (
    <Suspense fallback={<div />}>
      <AIChatPartnerLoadQuery />
    </Suspense>
  );
};
