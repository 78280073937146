import React, { useState, useEffect } from 'react';
import {
  Grid2, Box, CardSmallColor, Dialog, IcSvgList, Grid2Ct, styled, WCLThemeProps,
  Ty, IcSvg,
} from '@languageconvo/wcl';
import * as Sentry from '@sentry/react';
import { useNavigate } from 'react-router-dom';
import { useFragment } from 'react-relay';
import { useTranslation } from 'react-i18next';
import { PackagesDetail } from './relay/PackagesDetail';
import { sitedata } from '../../../../utils/sitedata';
import { PurchaseChooseFragment } from './relay/PurchaseChoose';
import { SmallcardMaxWidthContain } from '../../common/PurchaseCommon';

export const PurchaseChoosePage2 = ({ fragmentReference, setPaypageLoading }: any) => {
  // #region general

  // translation files, from here we will read all the content which render on the screen
  const { t } = useTranslation(['purchaseChoose', 'languageLocations']);
  // this will be for navigation to next (/pay) page
  const navigate = useNavigate();
  // language the user currently has selected
  const [selectedlanguageId, setSelectedLanguageId] = useState();

  // package related vars
  // currently selected package. we set the default (20 hour pkg) below
  const [selectedpkgName, setSelectedpkgName] = useState<string>();
  // to show packages based on each selected lanaguage in dropdown.
  const [packagesArray, setPackagesArray] = useState<PackagesArrayItem[]>([]);
  // this state will hold the pkgId which is selected by user
  const [selectedpkgId, setSelectedpkgId] = useState<number>();

  // price releated vars
  // calculating the per hour price of each lang.
  const [selectedpkgPerHourPrice, setSelectedpkgPerHourPrice] = useState(0);
  // setting selected package price to send in mutation
  const [selectedpkgPrice, setSelectedpkgPrice] = useState<number>(0);
  // TODO: we should be formatting these numbers, rounding off zeros etc. for now that works
  //  but we should ensure it by using explicit formatting
  // display for various parts of the price
  const displayTotalPrice = selectedpkgPrice;
  const displayPerHourPrice = selectedpkgPerHourPrice.toFixed(2);
  const displayPriceOverview = `$${displayPerHourPrice}/Hr\u00A0\u00A0\u00A0$${displayTotalPrice} Total`;

  // #endregion

  // #region reading lang learn data from relay store

  /**
   * here we are reading the language id (studentLangLearnId) that student has currently selected
   * Reason: we need this id in 2 main places
   *    1 - from the sitedata we have to find the language name and flag using this
   *        id we have a check below, if the element id matches this "studentLangLearnId"
   *        we store that elements information in a variable so we can render that on UI.
   *    2 - whenever there is a change in relay store we get the updated "studentLangLearnId"
   *        and we have to prepare the packages for that particular language, so to handle that
   *        situation we use this "studentLangLearnId"
  */
  const fragmentRefForUserLangLearn = fragmentReference.users_connection.edges[0].node;
  const data = useFragment(PurchaseChooseFragment, fragmentRefForUserLangLearn);
  const studentLangLearnId = data.lang_learning;

  // all languages we currently offer, their names and flags are present in sitedata
  // we loop through them and get the one which is selected by the user using "studentLangLearnId"
  // Reason: because we wanted to show this language and it's flag on the UI
  let langlearnIcon;
  let langlearnName = '';
  sitedata.languages.forEach((el) => {
    if (el.id === studentLangLearnId) {
      langlearnIcon = el.flagIcon;
      langlearnName = t(`${el.id}`, { ns: 'languageLocations' });
    }
  });

  // log if we didn't find an icon, and set the flag to a default
  if (!langlearnIcon) {
    langlearnIcon = sitedata.languages[0].flagIcon;
    Sentry.captureException(
      new Error('IMPORTANT - should never happen. No icon was found in MobileLanglearning'),
      {
        extra: {
          icon: langlearnIcon,
        }
      }
    );
  }

  // #endregion

  // #region reading packages details data from relay store

  const packagesDetailsData = useFragment(PackagesDetail, fragmentReference);
  const packagesData = packagesDetailsData.locations_connection.edges;

  // initialize an empty array to store languages, here we are preparing the data
  // which we will use to make packages for select component
  const languages: any = [];
  if (packagesData && packagesData.length) {
    packagesData?.forEach((info: any) => {
      if (info) {
        const newObj = {
          id: info?.node?.pk,
          name: info?.node?.title,
          value: info?.node?.packs,
        };

        // Pushing the new object to the languages array
        languages.push(newObj);
      }
    });
  }

  // #endregion

  // #region preparing data for inital page load

  // generic function which takes an array and return 20 Hours package as default.
  // we are calling this function from useEffect below, which will run on pageload 
  // as well as whenever there is a change occur in user lang learn field.
  // Reason: we wanted to display some states on the UI and this function will set
  // the values for those fields
  const userSelectedLanguage = (array: PackagesArrayItem[]): void => {
    const twentyHourPkg = array
      .filter((item: PackagesArrayItem) => (item.value.durationInHr === 20));
    // setting 20 hour pkg id by default if user don't change his pkg
    setSelectedpkgId(twentyHourPkg[0].value.pkgId);
    setSelectedpkgPrice(twentyHourPkg[0].value.price);
    setSelectedpkgName(twentyHourPkg[0].name);
    setSelectedpkgPerHourPrice(twentyHourPkg[0].value.pricePerHour);
  };

  // this function will take in an array of packages and make them
  // the format we want for our select component. So, we can show these as select 
  // options in select dropdown
  interface PackagesArrayItem {
    name: string;
    value: {
      duration: number;
      durationInHr: number;
      pkgId: number;
      price: number;
      pricePerHour: number;
      showBestPrice: boolean;
    }
  }
  const createPackagesArrayForSelectComponent = (array: any): PackagesArrayItem[] => {
    const tempArray = array.map((info: any) => {
      const durationInSeconds = info.duration;
      const durationInHours = durationInSeconds / 3600;
      const name = `${durationInHours} Hour Package`;
      const thePricePerHr = info.price / durationInHours;
      let bestprice = false;
      if (durationInHours >= 20) {
        bestprice = true;
      }

      // Creating an object with price duration and pkgId information
      const value = {
        price: info.price,
        pricePerHour: thePricePerHr,
        duration: durationInSeconds,
        durationInHr: durationInHours,
        pkgId: info.pk,
        showBestPrice: bestprice,
      };
      return { name, value };
    });

    // sort by duration, smallest to largest
    tempArray.sort((a: PackagesArrayItem, b: PackagesArrayItem) => {
      const durationA = a.value.duration;
      const durationB = b.value.duration;
      if (durationA < durationB) {
        return -1;
      }
      if (durationA > durationB) {
        return 1;
      }
      return 0;
    });

    return tempArray;
  };

  // this is the only useEffect which will call functions to prepare the
  // default value for packages which we will display as selected by default 
  // and prepare all the options of packages to pass in select component as options
  useEffect(() => {
    // Checking if languages is truthy
    if (languages) {
      // Filtering the languages array to find the preferred language
      const prefLang = languages.filter(
        (item: any) => (
          item.id === studentLangLearnId
        )
      );

      // setting default language id
      setSelectedLanguageId(prefLang[0]?.id);

      //  Checking if the preferred language has a value
      //  then we Map the preferred language value to create a new array of packages
      if (prefLang[0]?.value.length) {
        const newPackages = createPackagesArrayForSelectComponent(prefLang[0]?.value);
        setPackagesArray(newPackages);
        // generic function which takes an array and return 20 Hours package as default
        userSelectedLanguage(newPackages);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studentLangLearnId]);

  // #endregion

  // #region handle change for packages dropdown

  // user clicks to select a package. we get the package details and set them to our state vars
  const handlePackageSelect = (event: any) => {
    // find the package id that the user clicked on
    const thePkgId = parseInt(event.currentTarget.getAttribute('data-pkgid'), 10);
    let foundThePackage = false;
    packagesArray.forEach((el) => {
      if (el.value.pkgId === thePkgId) {
        foundThePackage = true;
        setSelectedpkgId(el.value.pkgId);
        setSelectedpkgPrice(el.value.price);
        setSelectedpkgName(el.name);
        setSelectedpkgPerHourPrice(el.value.pricePerHour);
      }
    });

    // log to sentry if we did not find the package
    if (!foundThePackage) {
      Sentry.captureException(
        new Error('BIG PROBLEM: handlePackageSelect did not find the package the user clicked on!'),
        {
          extra: {
            pkgid: thePkgId,
            langid: selectedlanguageId,
          }
        }
      );
    }

    // close the modal
    handleModalClosePkg();
  };

  // #endregion

  // #region mutation call for continue button

  /** when user clicks on the continue button, we redirect user to
   * purchase -> pay page with packId as param
  */
  const handleContinue = () => {
    navigate(`${sitedata.url.app.purchasePay}${selectedpkgId}`);
    setPaypageLoading(true);
  };

  // #endregion

  // #region modals

  // select package
  const [modalStatePkg, setModalStatePkg] = useState<boolean>(false);
  const handleModalOpenPkg = (event: any) => {
    event.preventDefault(); // prevents onClick from auto-reloading
    setModalStatePkg(true);
  };
  const handleModalClosePkg = () => {
    setModalStatePkg(false);
  };

  // price overview
  const [modalStatePrice, setModalStatePrice] = useState<boolean>(false);
  const handleModalOpenPrice = (event: any) => {
    event.preventDefault(); // prevents onClick from auto-reloading
    setModalStatePrice(true);
  };
  const handleModalClosePrice = () => {
    setModalStatePrice(false);
  };

  // #endregion

  return (
    <>
      {/* main contents */}
      <Grid2Ct sx={{ mt: { xs: 1, sm: 2 } }}>
        {/* package */}
        <Grid2 xs={12}>
          <SmallCardContainer>
            <CardSmallColor
              // when component first renders the selectedPkg might not be set yet; show
              // blank text in that case
              text={selectedpkgName || ''}
              centeredSubtext="(click to change)"
              icon={IcSvgList.box1}
              color="accentPurple1"
              hovercursor="pointer"
              onClick={handleModalOpenPkg}
              cp={{ 'data-intercom': 'purchpage-package' }}
            />
          </SmallCardContainer>
        </Grid2>

        {/* price details */}
        <Grid2 xs={12} sx={{ pt: 0 }}>
          <SmallCardContainer>
            <CardSmallColor
              text={displayPriceOverview}
              icon={langlearnIcon}
              color="accentPurple1"
              hovercursor="pointer"
              onClick={handleModalOpenPrice}
              cp={{ 'data-intercom': 'purchpage-price' }}
            />
          </SmallCardContainer>
        </Grid2>

        {/* continue to checkout */}
        <Grid2 xs={12} sx={{ pt: 0 }}>
          <SmallCardContainer>
            <CardSmallColor
              text="Continue to Checkout!"
              icon={IcSvgList.cart2}
              color="accentGreen1"
              hovercursor="pointer"
              onClick={() => handleContinue()}
            />
          </SmallCardContainer>
        </Grid2>
      </Grid2Ct>

      {/* choose package modal */}
      <Dialog
        isOpen={modalStatePkg}
        onClose={handleModalClosePkg}
        width="sm"
        color="accentPurple1"
      >
        <Grid2Ct>
          {/* text, don't show on xs. reason being, we want users on phones to be able to see
              the 20 hour package that has "lowest per hour price!" without having to scroll
           */}
          <Grid2 xs={12} sx={{ display: { xs: 'none', sm: 'block' } }}>
            <Ty align="center">
              Click on the package you want to purchase!
            </Ty>
          </Grid2>

          {packagesArray.map((el) => (
            <Grid2 xs={12}>
              <PackageContainer
                onClick={(event) => handlePackageSelect(event)}
                data-pkgid={el.value.pkgId}
              >
                {/* for sm and larger screens */}
                <Grid2Ct sx={{ display: { xs: 'none', sm: 'flex' } }}>
                  {/* package name (e.g. "20 Hour Package") */}
                  <Grid2 xs={12} sm={6}>
                    <AlignIconText>
                      <IcSvg icon={IcSvgList.box1} height="24px" width="24px" />&nbsp;&nbsp;&nbsp;
                      <Ty v="h2New" removeMb>
                        {el.name}
                      </Ty>
                    </AlignIconText>

                    {/* show "lowest price per hour!" for 20 and 50 hr packages */}
                    {el.value.showBestPrice ? (
                      <AlignIconText>
                        <IcSvg icon={IcSvgList.star1} height="16px" width="16px" />&nbsp;&nbsp;
                        <Ty removeMb>
                          Lowest price per hour!
                        </Ty>
                      </AlignIconText>
                    ) : null}
                  </Grid2>

                  {/* price per hour, total price */}
                  <Grid2 xs={12} sm={6}>
                    <Ty removeMb align="right">
                      ${el.value.pricePerHour.toFixed(2)} / Hour<br />
                      ${el.value.price} Total
                    </Ty>
                  </Grid2>
                </Grid2Ct>

                {/* for xs screens */}
                <Grid2Ct sx={{ display: { xs: 'flex', sm: 'none' } }}>
                  {/* package name (e.g. "20 Hour Package") */}
                  <Grid2 xs={12}>
                    <AlignIconText>
                      <IcSvg icon={IcSvgList.box1} height="24px" width="24px" />&nbsp;&nbsp;&nbsp;
                      <Ty v="h2New" removeMb>
                        {el.name}
                      </Ty>
                    </AlignIconText>
                  </Grid2>

                  {/* price per hour, total price. we remove top padding to condense things enough
                      so that on most phones, the "lowest price per hour!" for the 20 hour package
                      is visible without scrolling down
                   */}
                  <Grid2 xs={12} sx={{ pt: 0 }}>
                    <Ty removeMb>
                      ${el.value.pricePerHour.toFixed(2)} / Hour
                    </Ty>
                    <Ty removeMb>
                      ${el.value.price} Total
                    </Ty>
                  </Grid2>

                  {/* show "lowest price per hour!" for 20 and 50 hr packages */}
                  {el.value.showBestPrice ? (
                    <Grid2 xs={12}>
                      <AlignIconText>
                        <IcSvg icon={IcSvgList.star1} height="12px" width="12px" />&nbsp;&nbsp;&nbsp;
                        <Ty removeMb>
                          Lowest price per hour!
                        </Ty>
                      </AlignIconText>
                    </Grid2>
                  ) : null}
                </Grid2Ct>
              </PackageContainer>
            </Grid2>
          ))}
        </Grid2Ct>
      </Dialog>

      <Dialog
        isOpen={modalStatePrice}
        onClose={handleModalClosePrice}
        width="sm"
        color="accentPurple1"
      >
        <Grid2Ct>
          <Grid2 xs={12}>
            <Ty>
              You&apos;ve selected a <strong>{selectedpkgName} </strong>
              of <strong>{langlearnName}</strong> lessons!
              <br /><br />
              To select a different number of hours, just click the purple box above this one. To
              select a different language, click the language flag in the navigation bar!
            </Ty>
          </Grid2>
        </Grid2Ct>
      </Dialog>
    </>
  );
};

// #region subcomponents

// #region container for each small card
const SmallCardContainer = ({ children }: any) => (
  <Box display="flex" justifyContent="center">
    <SmallcardMaxWidthContain>
      {children}
    </SmallcardMaxWidthContain>
  </Box>
);

// #endregion

// #region choose package modal contents

const AlignIconText = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const PackageContainer = styled(Box)<WCLThemeProps>(({ theme }) => ({
  backgroundColor: `${theme?.ourTheme.colors.cp.bgInnerCard.accentPurple1}`,
  borderRadius: `${theme.ourTheme.borders.borderRadius.medium}px`,
  padding: '16px 20px 16px 20px',
  cursor: 'pointer',
}));

// #endregion

// loading state
export const ChoosePackageLoading = () => (
  <Grid2Ct sx={{ mt: { xs: 1, sm: 2 } }}>
    <Grid2 xs={12}>
      <SmallCardContainer>
        <CardSmallColor
          text=""
          centeredSubtext="."
          icon={IcSvgList.box1}
          color="accentPurple1"
        />
      </SmallCardContainer>
    </Grid2>

    <Grid2 xs={12} sx={{ pt: 0 }}>
      <SmallCardContainer>
        <CardSmallColor
          text=""
          icon={IcSvgList.flagblank1}
          color="accentPurple1"
        />
      </SmallCardContainer>
    </Grid2>

    <Grid2 xs={12} sx={{ pt: 0 }}>
      <SmallCardContainer>
        <CardSmallColor
          text=""
          icon={IcSvgList.cart2}
          color="accentGreen1"
        />
      </SmallCardContainer>
    </Grid2>
  </Grid2Ct>
);

// #endregion
