import React, {
  createContext, useState, Dispatch, SetStateAction
} from 'react';

interface Message {
  userType: number; // 1 for student, 2 for AI
  content: string; // The text of the message
}

interface AIChatPartnertContextProps {
  openSkillModal: boolean;
  setOpenSkillModal: Dispatch<SetStateAction<boolean>>
  openToggleTranslationModal: boolean;
  setOpenToggleTranslationModal: Dispatch<SetStateAction<boolean>>
  threadId: string | null;
  setThreadId: Dispatch<SetStateAction<string | null>>;
  noOfQuestionUserAsked: number;
  setNoOfQuestionUserAsked: Dispatch<SetStateAction<number>>;
  isLimitReached: boolean;
  setIsLimitReached: Dispatch<SetStateAction<boolean>>;
  disableAskQuestionBtn: boolean;
  setdisableAskQuestionBtn: Dispatch<SetStateAction<boolean>>;
  messages: Message[];
  setMessages: Dispatch<SetStateAction<Message[]>>;
  firstQuestionAsked: boolean;
  setFirstQuestionAsked: Dispatch<SetStateAction<boolean>>;
  isMutationError: boolean;
  setIsMutationError: Dispatch<SetStateAction<boolean>>;
  isPaidLimitReached: boolean;
  setIsPaidLimitReached: Dispatch<SetStateAction<boolean>>;
  isFirstResFromBE: boolean;
   setIsFirstResFromBE: Dispatch<SetStateAction<boolean>>;
   isSuggestResBtnClick: boolean,
   setIsSuggestResBtnClick: Dispatch<SetStateAction<boolean>>;
}

const AIChatPartnerContext = createContext<AIChatPartnertContextProps>({
  openSkillModal: false,
  setOpenSkillModal: () => { },
  openToggleTranslationModal: false,
  setOpenToggleTranslationModal: () => { },
  threadId: null,
  setThreadId: () => { },
  noOfQuestionUserAsked: 0,
  setNoOfQuestionUserAsked: () => { },
  isLimitReached: false,
  setIsLimitReached: () => { },
  disableAskQuestionBtn: false,
  setdisableAskQuestionBtn: () => { },
  messages: [],
  setMessages: () => { },
  firstQuestionAsked: false,
  setFirstQuestionAsked: () => { },
  isMutationError: false,
  setIsMutationError: () => {},
  isPaidLimitReached: false,
  setIsPaidLimitReached: () => {},
  isFirstResFromBE: false,
  setIsFirstResFromBE: () => {},
  isSuggestResBtnClick: false,
  setIsSuggestResBtnClick: () => {},

});

interface AIChatPartnerProps {
  children: React.ReactNode;
}

const AIChatPartnerProvider: React.FC<AIChatPartnerProps> = ({ children }) => {
  // state variable to open/close skills modal
  const [openSkillModal, setOpenSkillModal] = useState<boolean>(false);

  // state variable to open/close wants_tranlsation dialog
  const [openToggleTranslationModal, setOpenToggleTranslationModal] = useState<boolean>(false);
  // state to hold the threadId, because we have to send it when user ask another question
  // this is because we wanted to let backend know, they should not create a new thread now
  const [threadId, setThreadId] = useState<string | null>(null);

  // state to track number of questions user has asked based on it we 
  // send threadId null when user's has asked 15 question in a session
  const [noOfQuestionUserAsked, setNoOfQuestionUserAsked] = useState<number>(0);

  // error state for limit reach
  const [isLimitReached, setIsLimitReached] = useState<boolean>(false);

  // state to disable ask question button when our AI modal is giving answer
  const [disableAskQuestionBtn, setdisableAskQuestionBtn] = useState<boolean>(false);

  // state to hold all the user's asked question and AI Response, here userType 1
  //  means student's question userType2 show AI response
  const [messages, setMessages] = useState<{ userType: number, content: string }[]>([]);

  // state that tells us if/when the user has asked their first question. we'll use this
  // to show/hide some components on the page
  const [firstQuestionAsked, setFirstQuestionAsked] = useState<boolean>(false);

  // state to show error in the dialog in case of mutation fails.
  const [isMutationError, setIsMutationError] = useState<boolean>(false);

  // state to check paid user limit reached, if yes we are showing a card to our user
  const [isPaidLimitReached, setIsPaidLimitReached] = useState<boolean>(false);

  // state to track whether the first response from the backend has been received. 
  // This helps in making decisions when the user changes the language from the sidenav. 
  // If the backend response is complete, we allow the user to communicate with the
  //  selected language assistant.
  const [isFirstResFromBE, setIsFirstResFromBE] = useState<boolean>(false);

  // state to track user clicked on Suggest Response button based on this we are sending 
  // api call to our BE end point
  const [isSuggestResBtnClick, setIsSuggestResBtnClick] = useState<boolean>(false);

  const value = React.useMemo(
    () => ({
      openSkillModal,
      setOpenSkillModal,
      openToggleTranslationModal,
      setOpenToggleTranslationModal,
      threadId,
      setThreadId,
      noOfQuestionUserAsked,
      setNoOfQuestionUserAsked,
      isLimitReached,
      setIsLimitReached,
      disableAskQuestionBtn,
      setdisableAskQuestionBtn,
      messages,
      setMessages,
      firstQuestionAsked,
      setFirstQuestionAsked,
      isMutationError,
      setIsMutationError,
      isPaidLimitReached,
      setIsPaidLimitReached,
      isFirstResFromBE,
      setIsFirstResFromBE,
      isSuggestResBtnClick,
      setIsSuggestResBtnClick
    }),
    [openSkillModal, threadId, setThreadId, noOfQuestionUserAsked, setNoOfQuestionUserAsked,
      openToggleTranslationModal, setOpenToggleTranslationModal, isLimitReached,
      setIsLimitReached, disableAskQuestionBtn, setdisableAskQuestionBtn,
      messages, setMessages, firstQuestionAsked, setFirstQuestionAsked,
      isMutationError, setIsMutationError, isPaidLimitReached,
      setIsPaidLimitReached, isFirstResFromBE, setIsFirstResFromBE,
      isSuggestResBtnClick, setIsSuggestResBtnClick]
  );
  return <AIChatPartnerContext.Provider value={value}>{children}</AIChatPartnerContext.Provider>;
};

export { AIChatPartnerProvider, AIChatPartnerContext };
