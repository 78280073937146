import { useState } from 'react';
import * as Sentry from '@sentry/react';
import { useMutation } from 'react-relay';
import { toast } from 'react-toastify';
import { StuSettingsUpdateaicsettingsMutation } from '../relay/StuSettingsUpdateaicsettings';
import useAIChatPartner from './useAIChatPartner';
import { Messages } from '../enum/Messages';
import { AiChatPartnerError } from '../enum/Errors';

/**
 * custom hook to save student preferences for the AI chat partner settings to the backend.
 * the hook manages options like whether the student wants translations enabled and
 * the preferred skill level.
 */
export const useSaveStdPreferences = () => {
  // state to enable/disable the `continue` button, we are disabling the button to avoid
  //  mutiple clicks(which can leads to multiple api calls.)
  const [loading, setIsLoading] = useState<boolean>(false);

  // Destructure `setThreadId` from `useAIChatPartner` to allow resetting thread context when
  // settings like translation preference or level change.
  const {
    setThreadId, setNoOfQuestionUserAsked, setOpenSkillModal, setOpenToggleTranslationModal,
    setMessages, setFirstQuestionAsked, setIsMutationError
  } = useAIChatPartner();

  // here we are configuraing our mutation call
  const [commitMutationToSaveStdPreferences] = useMutation(
    StuSettingsUpdateaicsettingsMutation
  );

  const saveStdPreference = (
    wants_translation: number,
    skill_level: number,
  ) => {
    try {
      // disabled the continue button as user click on button
      setIsLoading(true);
      // here we are executing our mutation 
      commitMutationToSaveStdPreferences({
        variables: {
          translation: wants_translation,
          level: skill_level,
        },
        onCompleted() {
          // as the data save to our DB, enable the continue button again
          setIsLoading(false);
          // set the threadIdTo null when user change his level/toglle between translation
          setThreadId(null);
          // reset the no of question user asked as new thread has been started so
          // start message count from 0 again.
          setNoOfQuestionUserAsked(0);
          // close the skills dialog
          setOpenSkillModal(false);
          // close the translation dialog
          setOpenToggleTranslationModal(false);
          // reseting the previous msg as user is going to chat with a new ai personality
          setMessages([]);

          setFirstQuestionAsked(false);
        },
        onError(err: any) {
          const jsonMsg = JSON.parse(err.message);

          if (jsonMsg.extensions.code === AiChatPartnerError.InputError) {
            toast.error(Messages.InvalidInput);

            setIsMutationError(true);
          } else {
            toast.error(Messages.UnExpected);
          }

          // for now logging the error to the Sentry so we can now how many times our this call
          // fails
          Sentry.captureException(err);
        },
      });
    } catch (error) {
      // logging the error to sentry in case of unexpected error
      Sentry.captureException(error);
    }
  };

  return { saveStdPreference, loading };
};
