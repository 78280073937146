import {
  Box,
} from '@mui/material';
import { vars } from '../../../utils/vars';
import { Props } from './interface';
import { CustomCard, SubtextSpan } from './index.style';
import { Ic, IcSvg } from '../../datadisplay';

// this is the title text. if centeredSubtext is provided, we have to create a special setup with main title above
// the subtext. if it's not provided, just simple text is needed
const TextValNew = ({
  text, centeredSubtext, aligntx,
}: any) => (
  (centeredSubtext ? (
    <div style={{ textAlign: 'center', marginTop: '5px' }}>
      <span style={{ display: 'inline-block' }}>
        {text}
      </span>
      <br />
      <SubtextSpan>
        {centeredSubtext}
      </SubtextSpan>
    </div>
  ) : (
    <div style={{ textAlign: (aligntx === 1 ? 'center' : 'left') }}>
      <span>
        { text }
      </span>
    </div>
  ))
);

/**
 * A small, colored card we often use inside of CardStandard. This always has an icon and text title.
 *
 * It can be given onClick functionality (often used for showing a modal), or wrapped in a Link component
 * (typically with showArrow=true and hovercursor='cursor) to link to another page.
 */
export const CardSmallColor = (props: Props) => {
  const {
    color,
    text,
    icon,
    showArrow = false,
    cp,
    onClick,
    align,
    centeredSubtext,
    hovercursor = 'none',
    smallerfont = false,
  } = props;

  let alignText = 1;
  if (align === 'left') {
    alignText = 2;
  }

  const baseNew = (
    <CustomCard
      color={color}
      onClick={onClick}
      hovercursor={hovercursor}
      smallerfont={smallerfont}
      {...cp}
    >
      {/* icon */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {/* left icon */}
        <Box width="34px" minWidth="34px">
          <IcSvg icon={icon} width={vars.svgIconStandardSize} height={vars.svgIconStandardSize} />
        </Box>

        {/* text */}
        {/* flexGrow causes this to take the full width of the card, minus the icon and arrow divs */}
        <Box sx={{ flexGrow: 1 }}>
          <TextValNew text={text} centeredSubtext={centeredSubtext} aligntx={alignText} />
        </Box>

        {/* right arrow, if needed */}
        { showArrow ? (
          <Box width="40px" minWidth="40px">
            {/* right align the arrow */}
            <Box display="flex" justifyContent="flex-end">
              <Ic iconName="circle-chevron-right" color={color} iconStyle="duotone" size="rel-xl" />
            </Box>
          </Box>
        ) : (
          // even if we don't want a right arrow, we have a box with some width. this looks
          // better with centered text.
          <Box width="8px" minWidth="8px">
            &nbsp;
          </Box>
        ) }
      </Box>
    </CustomCard>
  );

  return baseNew;
};
