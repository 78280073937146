import React from 'react';
import { useFragment } from 'react-relay';
import { DateTime } from 'luxon';
import { GetUserPreferencesFragment$key } from './relay/__generated__/GetUserPreferencesFragment.graphql';
import { GetUserPreferencesFragment } from './relay/GetUserPreferences';
import { StdPreferences } from './components/StdPreferences';
import { PracticeWithAIChatPartner } from './AIChatPartner2';
import { GetUserPreferencesLangLearningFragment$key } from './relay/__generated__/GetUserPreferencesLangLearningFragment.graphql';

interface Props {
  fragmentRefForUserPrefrences: GetUserPreferencesFragment$key;
  fragmentRefForLngLearning: GetUserPreferencesLangLearningFragment$key
}

// AIChatPartner is our decider component either we need to show AIChatPartner OR 
// ask student to set their  prefrences
export const AIChatPartner = ({
  fragmentRefForUserPrefrences,
  fragmentRefForLngLearning
}: Props) => {
  // reading data from relay store via fragment, we need the data to make decision
  // either we need to show AI Partner component OR we need show StdPreferences 
  // component(here user will set his preferences select their level
  // set & they want translation or not).
  const userPreferences = useFragment(GetUserPreferencesFragment, fragmentRefForUserPrefrences);

  /* checking if 90 days have passed since `aic_setsettings`
   - converting `aic_setsettings` to a Luxon DateTime object.
   - Adds 90 days to the original date.
   - comparing it to the current date; if the resulting date is earlier than now, 90 
    days have passed, and settings are considered expired.
  */
  const isSettingsExpired = DateTime.fromISO(userPreferences.aic_setsettings).plus({ days: 90 })
    < DateTime.now();

  return (
    // we are showing StdPreferences page when  aic_setsettings is null OR set_settings > 90days
    userPreferences.aic_setsettings === null || isSettingsExpired ? (
      <StdPreferences userPreferences={userPreferences} />
    ) : (
      <PracticeWithAIChatPartner
        fragmentRefForUserPrefrences={fragmentRefForUserPrefrences}
        fragmentRefForLngLearning={fragmentRefForLngLearning}
      />
    )
  );
};
