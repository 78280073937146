import React from 'react';
import {
  Button, Dialog, Grid2, Grid2Ct, RadioGroup, Ty,
} from '@languageconvo/wcl';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFragment } from 'react-relay';
import { WantsTranslation } from '../../schema/WantsTranslation';
import { GetUserPreferencesFragment } from '../../relay/GetUserPreferences';
import { GetUserPreferencesFragment$key } from '../../relay/__generated__/GetUserPreferencesFragment.graphql';
import { useSaveStdPreferences } from '../../hooks/useSaveStdPreferences';
import useAIChatPartner from '../../hooks/useAIChatPartner';

interface Props {
  fragmentRefForUserPrefrences: GetUserPreferencesFragment$key;
}

export const TranslationToggleDialog = ({ fragmentRefForUserPrefrences }: Props) => {
  // reading data from relay store via fragment, we need the data
  const userPreferences = useFragment(GetUserPreferencesFragment, fragmentRefForUserPrefrences);

  const {
    openToggleTranslationModal,
    setOpenToggleTranslationModal,
    isMutationError
  } = useAIChatPartner();

  // we have used react-hook-form here so we don't have to manually create the state and manage
  // that state, we pass in the default schema for validations and default values data we want
  // to render on the screen
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({
    reValidateMode: 'onChange',
    resolver: yupResolver(WantsTranslation),
    defaultValues: {
      wants_translation: userPreferences.aic_translate ?? 1,
    },
  });

  /**
   * there are 3 places from where we are getting user preferences 
   * instead of duplicating same code, we created a hook, which is mutating data
   * from single place and exposing different states, i-e laoding, mutate...
   */
  const { saveStdPreference, loading } = useSaveStdPreferences();

  const saveTranslationChoice = (data: any) => {
    saveStdPreference(data.wants_translation, userPreferences.aic_level);
  };

  // #region for radio option

  // options array for translation
  const translationOption = [
    { label: 'Translate Responses', value: 1 },
    { label: 'Do Not Translate', value: 0 },
  ];

  // #endregion

  return (
    <Dialog
      isOpen={openToggleTranslationModal}
      onClose={() => setOpenToggleTranslationModal(!openToggleTranslationModal)}
      width="xs"
      color="accentGreen1"
    >
      <Grid2Ct>
        {/* radio */}
        <Grid2 xs={12}>
          <Controller
            name="wants_translation"
            control={control}
            render={({ field: { onChange, value } }) => (
              <RadioGroup
                options={translationOption}
                value={value}
                onChange={onChange}
                defaultValue={userPreferences.aic_translate}
              />
            )}
          />
        </Grid2>

        {/* note */}
        <Grid2 xs={12}>
          <Ty>
            <strong>Note</strong>: changing this will start a new conversation
          </Ty>
        </Grid2>

        {/* mutation error alert */}
        {isMutationError && (
        <Grid2 xs={12}>
          <Ty>
            Oops! An error occurred. Please choose another option and try again.
          </Ty>
        </Grid2>
        )}

        {/* no option selected alert */}
        {Object.keys(errors).length > 0 && (
          <Grid2 xs={12}>
            <Ty>
              Oops! It looks like you forgot to select an option above.
            </Ty>
          </Grid2>
        )}

        {/* save button */}
        <Grid2 xs={12} sx={{ mt: 1 }}>
          <Button
            color="accentGreen1"
            disabled={loading}
            isLoading={loading}
            onClick={handleSubmit(saveTranslationChoice)}
          >
            Save Change
          </Button>
        </Grid2>
      </Grid2Ct>
    </Dialog>
  );
};
