import React from 'react';
import {
  Button, Grid2, Ty,
} from '@languageconvo/wcl';
import useAIChatPartner from './hooks/useAIChatPartner';

export const AIChatPartnerLimitReached = () => {
  const {
    isPaidLimitReached, setIsPaidLimitReached, setNoOfQuestionUserAsked, setThreadId, setMessages,
    disableAskQuestionBtn
  } = useAIChatPartner();

  const handleContinueClick = () => {
    // as user click on continue button we are going to start a new thread
    // so need to reset all these state
    // rest the count of user asked question
    setNoOfQuestionUserAsked(0);
    // setThreadId to null so we can start a new thread.
    setThreadId(null);
    // reseting the previous messages
    setMessages([]);
    // allow user to chat with a new AI personality
    setIsPaidLimitReached(false);
  };

  // usage limit reached for paid users. they can click to start a new chat
  if (isPaidLimitReached) {
    return (
      <Grid2 xs={12} sm={10} smOffset={1} lg={8} lgOffset={2} sx={{ mt: 4 }}>
        <Ty align="center">
          <strong>Great Work!</strong>
        </Ty>

        <Ty align="center">
          Great work, that was a long, successful conversation! Click the button below
          to start a new conversation with a new chat partner:
        </Ty>

        <Grid2 display="flex" justifyContent="center">
          <Button onClick={handleContinueClick} disabled={disableAskQuestionBtn} color="accentGreen1">
            Start New
          </Button>
        </Grid2>
      </Grid2>
    );
  }

  // usage limit reached for free users
  return (
    <Grid2 xs={12} sm={10} smOffset={1} lg={8} lgOffset={2} sx={{ mt: 4 }}>
      <Ty align="center">
        <strong>Free Usage Limit Reached</strong>
      </Ty>
      <Ty align="center">
        It looks like you have reached the free usage limit for now. If you take private lessons
        or group classes, you get unlimited access to this and our other practice tools!
      </Ty>
    </Grid2>
  );
};
