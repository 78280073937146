import {
  Grid2, Grid2Ct, TextField, CardSmallColorIcon, Box,
} from '@languageconvo/wcl';
import React, {
  Dispatch, SetStateAction, useEffect, useLayoutEffect
} from 'react';
import * as Sentry from '@sentry/react';
import { toast } from 'react-toastify';
import { useFragment } from 'react-relay';
import { isJsonString } from '../../../utils/isJSONString';
import { getStuSecurityFromLCStorage } from '../../../utils/lcStorage';
import { GetUserPreferencesFragment, GetUserPreferencesLangLearningFragment } from './relay/GetUserPreferences';
import { AiChatPartnerError } from './enum/Errors';
import { Messages } from './enum/Messages';
import { GetUserPreferencesFragment$key } from './relay/__generated__/GetUserPreferencesFragment.graphql';
import useAIChatPartner from './hooks/useAIChatPartner';
import { GetUserPreferencesLangLearningFragment$key } from './relay/__generated__/GetUserPreferencesLangLearningFragment.graphql';

interface InputComponentProps {
  userInput: string;
  setUserInput: Dispatch<SetStateAction<string>>;
  setStreamingMessage: Dispatch<SetStateAction<string>>;
  fragmentRefForUserPrefrences: GetUserPreferencesFragment$key;
  fragmentRefForLngLearning: GetUserPreferencesLangLearningFragment$key
}

export const InputComponent = (props: InputComponentProps) => {
  // #region general

  // the max number of messages a paid user can send, before we ask them to start a new convo.
  // as conversations get longer, the input tokens becomes larger and larger which can cause cost
  // to get high
  const maxQuestions = 50;

  // extract necessary state variable from the AI chat context using the useAIChatPartner hook.
  const {
    threadId, setThreadId, noOfQuestionUserAsked, setNoOfQuestionUserAsked, setIsLimitReached,
    disableAskQuestionBtn, setdisableAskQuestionBtn, setMessages, setFirstQuestionAsked,
    setIsPaidLimitReached, isPaidLimitReached, isFirstResFromBE, setIsFirstResFromBE,
    isSuggestResBtnClick, setIsSuggestResBtnClick
  } = useAIChatPartner();

  // extracting props so we can use them in this component
  const {
    userInput,
    setUserInput,
    setStreamingMessage,
    fragmentRefForUserPrefrences,
    fragmentRefForLngLearning
  } = props;

  // reading data for user preferences of level and translation
  const userPreferencesData = useFragment(GetUserPreferencesFragment, fragmentRefForUserPrefrences);

  // reading data for user selected language
  const userLangLearningData = useFragment(
    GetUserPreferencesLangLearningFragment,
    fragmentRefForLngLearning
  );

  // retrieve the stored student security details to get jwt 
  // token for authentication in later requests.
  const lcData = getStuSecurityFromLCStorage();
  const jwtToken = lcData.jwt;

  // #endregion

  // #region to handle user language change

  // this useEffect will trigger when user change the language from the sidenav
  // here we are reseting all the states, so user can chat with the assistant of selected language
  useEffect(() => {
    if (isFirstResFromBE) {
      // rest the no of user asked question to 0, so user can chat with new assistant 
      setNoOfQuestionUserAsked(0);
      // set the thread id null so BE create a new thread,
      setThreadId(null);
      // empty the msgs array as user is going to meet wit a new AI, so keeping the old msgs
      //  does not make sense
      setMessages([]);
    } else {
      // do nothing
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLangLearningData]);
  // #endregion

  // #region functions for ask question

  // Function to process each line
  const processLine = (line: string) => {
    let returnedValue = '';
    if (line.startsWith('data: ')) {
      // removing 'data: ' prefix recived from BE,as we don't want to show the user's
      const actualData = line.slice(6);

      // checking if the actual data is Json String
      const jsonString = isJsonString(actualData);

      if (jsonString) {
        // if data is in valid json form we are parsing it and extracting message from it
        const jsonData = JSON.parse(actualData);
        // setting threadId which will used when user's asked next question, it will
        // help BE to not always create new thread for each question
        setThreadId(jsonData.threadId);

        if (jsonData.message.content && jsonData.message.content !== '[DONE!]') {
          // storing the response receiving from BE and showing it on the UI in the 
          // form of streaming
          setStreamingMessage((prevMsg: any) => prevMsg + jsonData.message.content);

          returnedValue = jsonData.message.content;
        }
      } else {
        Sentry.captureException(
          new Error('ai chatpartner - invalid json provided by backend'),
          {
            extra: {
              dt: actualData,
            }
          }
        );
      }
    }

    return returnedValue;
  };

  // we need to send the api call as user lands on this page
  // so we are calling  handleAskQuestion function as user loads the page
  useLayoutEffect(() => {
    if (noOfQuestionUserAsked === 0) {
      handleAskQuestion();
    } else if (isSuggestResBtnClick) {
      // call handleAskQuestion again when user click on Suggest response btn.
      handleAskQuestion();
      // clear the input field,
      setUserInput('');
    } else {
      // do nothing
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noOfQuestionUserAsked, isSuggestResBtnClick]);

  const handleAskQuestion = async () => {
    if (noOfQuestionUserAsked === maxQuestions) {
      // show the user limit reacher popup
      setIsPaidLimitReached(true);
      // clear the user input
      setUserInput('');
    } else {
      // do nothing
    }

    if ((userInput.trim() !== '' && !disableAskQuestionBtn && noOfQuestionUserAsked < maxQuestions)
      || noOfQuestionUserAsked === 0) {
      // helps us know if the user has asked at least 1 question
      setFirstQuestionAsked(true);
      // clearing input field
      setUserInput('');

      if (noOfQuestionUserAsked >= maxQuestions) {
        // when user asked maxQuestions questions and ask next question we show him dialog
        setNoOfQuestionUserAsked(noOfQuestionUserAsked + 1);
      } else {
        // increament as user ask question
        setNoOfQuestionUserAsked(noOfQuestionUserAsked + 1);
      }
      // disabled the `Send` button to prevent multiple submissions.
      setdisableAskQuestionBtn(true);

      // hide the message that we have showed to the user's when user 
      // exceed  the limit
      setIsLimitReached(false);

      if (!isSuggestResBtnClick) {
        // maintaining an array of user's question
        setMessages((prevMessages: any) => [
          ...prevMessages,
          { userType: 1, content: userInput },
        ]);
      } else {
        // do nothing , as we don't want to show 'Help me with a response'
        // msg on the UI,
      }

      try {
        // conditionally passing the `requestBody` as for the first time BE need
        // all fields but in the second msg BE only need currentThreadId & userInput
        const requestBody = noOfQuestionUserAsked === 0 ? {
          needTranslation: userPreferencesData.aic_translate,
          level: userPreferencesData.aic_level,
          languageId: userLangLearningData.lang_learning,
          userQuestion: null,
          currentThreadId: null
        } : {
          userQuestion: userInput,
          currentThreadId: noOfQuestionUserAsked >= maxQuestions ? null : threadId
        };

        // Send POST request and handle the streaming response
        const response = await fetch(`${process.env.REACT_APP_CHATPARTNER_URL}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'text/event-stream',
            Authorization: `Bearer ${jwtToken}`,
          },
          body: JSON.stringify(requestBody),
        });

        // we are storing our AI response 
        let streamingResponse = '';

        if (response.ok) {
          // Get reader to read the stream
          const reader = response!.body!.getReader();
          const decoder = new TextDecoder();

          // Loop to continuously read data from the stream
          // eslint-disable-next-line no-constant-condition
          while (true) {
            // eslint-disable-next-line no-await-in-loop
            const { done, value } = await reader!.read();

            if (done) {
              if (streamingResponse !== '') {
                // eslint-disable-next-line no-loop-func
                setMessages((prevMessages: any) => [
                  ...prevMessages,
                  { userType: 2, content: streamingResponse },
                ]);

                // when BE completes it's response it give us [DONE!] flag
                // as we received [DONE!] we setStreaming message to ' ', so when user asked next 
                // question previous response does not shown on the UI.
                setStreamingMessage('');

                // when our BE give us first response sucessfully we are setting this 
                // state to true, reason: when user lands on AI chat partner page, 
                setIsFirstResFromBE(true);
                // reseting the state to default value as we got complete response from AI,
                // so user can again make api call by clciking on `Suggest Response` Button.
                setIsSuggestResBtnClick(false);
              } else {
                // displaying toast to user
                toast.error(Messages.UnExpected);
                // if BE fails to send us response in between then we should make 
                // this state to false,
                setIsFirstResFromBE(false);
              }

              // enabling the ask question when response is shown to the user
              // so they can ask next question
              setdisableAskQuestionBtn(false);
              break; // Stop looping when done is true
            }

            // Decode and process the chunk of data
            const chunk = decoder.decode(value, { stream: true });

            // The chunk may have multiple lines; split them
            const lines = chunk.split('\n');

            // Process each line safely outside the loop
            for (let i = 0; i < lines.length; i += 1) {
              streamingResponse += processLine(lines[i]);
            }
          }
        } else {
          // in case of api call failure OR any error occur we are handling here
          const jsonRes = await response.json();

          if (jsonRes.extensions.code === AiChatPartnerError.LimitReached) {
            // making this state true so we can show a  message to inform user
            // that their free limit is exceed
            setIsLimitReached(true);

            // disabled the `Send` button to avoid multiple submissions
            setdisableAskQuestionBtn(false);
          } else if (jsonRes.extensions.code === AiChatPartnerError.InvalidInput) {
            // showing error message to the user
            toast.error(Messages.InvalidInput);
            // enabling the `Send` button so user can again ask qusestion
            setdisableAskQuestionBtn(false);
          } else {
            // showing error message to the user
            toast.error(Messages.UnExpected);
            // enabling the `Send` button so user can again ask qusestion
            setdisableAskQuestionBtn(false);
          }
        }
      } catch (err) {
        // logging the error to the sentry
        Sentry.captureException(err);
        // showing the toast message to the user.
        toast.error(Messages.UnExpected);
        // enabling the `Send` button so user can again ask qusestion
        setdisableAskQuestionBtn(false);
        setIsFirstResFromBE(false);
      }
    }
  };

  // #endregion

  return (
    <Grid2Ct>
      <Grid2 xs={12}>
        <Box sx={{ display: 'flex' }}>
          <TextField
            id="user-input"
            placeholder="Type a message"
            value={userInput}
            disabled={isPaidLimitReached}
            inputCp={{
              maxLength: 300,
              autoComplete: 'off'
            }}
            onChange={(e) => setUserInput(e.target.value)}
            // this is because we want to send the api call even if user pressed enter button
            cp={{
              onKeyDown: (e: any) => {
                if (e.key === 'Enter') {
                  handleAskQuestion();
                }
              },
            }}
          />

          <CardSmallColorIcon
            color="accentGreen1"
            hovercursor="pointer"
            icon={disableAskQuestionBtn ? 'hulahoop1' : 'write1'}
            onClick={handleAskQuestion}
            cp={{ sx: { ml: 2 } }}
          />
        </Box>
      </Grid2>
    </Grid2Ct>
  );
};
