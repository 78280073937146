import React from 'react';
import {
  Box, Button, Card, CardContent, Grid2, Grid2Ct, RadioGroup, Ty, LottiePlayer,
} from '@languageconvo/wcl';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { StudentPreferences } from '../schema/StudentPreferences';
import {
  OptionsContainers,
} from '../AIChatPartner.style';
import { useSaveStdPreferences } from '../hooks/useSaveStdPreferences';
import Alice from '../../../../common/assets/lottie/giraffeteacher.json';

interface Props {
  userPreferences: any;
}

// the first time the user loads this practice tool, here we display some info about the tool and
// allow them to initially set their level and whether or not they want to translate responses
export const StdPreferences = ({ userPreferences }: Props) => {
  // #region general 

  // we have used react-hook-form here so we don't have to manually create the state and manage
  // that state, we pass in the default schema for validations and default values data we want
  // to render on the screen
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({
    reValidateMode: 'onChange',
    resolver: yupResolver(StudentPreferences),
    defaultValues: {
      skill_level: userPreferences.aic_level ?? 1,
      wants_translation: userPreferences.aic_translate ?? 1,
    },
  });

  /**
   * there are 3 places from where we are getting user preferences 
   * instead of duplicating same code, we created a hook, which is mutating data
   * from single place and exposing different states, i-e laoding, mutate...
   */
  const { saveStdPreference, loading } = useSaveStdPreferences();

  // #endregion 

  // #region for mutation call 

  // function to save user preferences to our DB
  const saveUserPreference = (data: any) => {
    // destructuring `skill_level` and `wants_translation` and passing 
    // the user selected option to our custom hook to save data to db
    const { skill_level, wants_translation } = data;

    // passing user selected data to the custom hook, so we can store the user preference
    // to our db for future use,
    saveStdPreference(wants_translation, skill_level);
  };

  // #endregion

  // #region for radio option

  // our options array for skill level
  const skillLevelOption = [
    { label: 'Beginner', value: 1 },
    { label: 'Intermediate', value: 2 },
    { label: 'Advanced', value: 3 },
  ];

  //   options array for translation
  const translationOption = [
    { label: 'Translate Responses', value: 1 },
    { label: 'Do Not Translate', value: 0 },
  ];

  // #endregion

  return (
    <Card>
      <CardContent>
        <Grid2Ct>
          {/* giraffe, how it works */}
          <Grid2 xs={12} sx={{ mb: 2 }}>
            <Grid2Ct>
              {/* lottie, different heights for different screens */}
              <Grid2 xs={12} sx={{ display: { xs: 'none', xl: 'block' } }}>
                <LottiePlayer animationSrc={Alice} animationHeight={200} />
              </Grid2>
              <Grid2 xs={12} sx={{ display: { xs: 'none', sm: 'block', xl: 'none' } }}>
                <LottiePlayer animationSrc={Alice} animationHeight={180} />
              </Grid2>
              <Grid2 xs={12} sx={{ display: { xs: 'block', sm: 'none' } }}>
                <LottiePlayer animationSrc={Alice} animationHeight={160} />
              </Grid2>

              {/* text */}
              <Grid2
                xs={12}
                sm={8}
                smOffset={2}
                lg={8}
                lgOffset={2}
                xl={6}
                xlOffset={3}
                sx={{ pt: 0 }}
              >
                <Ty align="center">
                  Hi, I&apos;m Gerald the giraffe! I can help you practice conversation
                  any time, 24/7; it&apos;s a great way to learn new words, work on
                  your grammar, and a lot more between lessons with your teacher.
                  Just set the two settings below to
                  get started (don&apos;t worry you can easily change them later)!
                </Ty>
              </Grid2>
            </Grid2Ct>
          </Grid2>

          {/* level */}
          <Grid2 xs={12} sm={6} xl={5} xlOffset={1} sx={{ display: 'flex' }}>
            <OptionsContainers>
              <Ty v="subheading">
                Select a difficulty level:
              </Ty>

              <Controller
                name="skill_level"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <RadioGroup
                    options={skillLevelOption}
                    value={value}
                    onChange={onChange}
                    defaultValue={userPreferences.aic_level}
                  />
                )}
              />
            </OptionsContainers>
          </Grid2>

          {/* translate */}
          <Grid2 xs={12} sm={6} xl={5} sx={{ display: 'flex' }}>
            <OptionsContainers>
              <Ty v="subheading">
                Do you want to see translations of each message?
              </Ty>

              <Controller
                name="wants_translation"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <RadioGroup
                    options={translationOption}
                    value={value}
                    onChange={onChange}
                    defaultValue={userPreferences.aic_translate}
                  />
                )}
              />
            </OptionsContainers>
          </Grid2>

          {/* error */}
          {Object.keys(errors).length > 0 && (
            <Grid2 xs={12}>
              <Ty align="center">
                Oops! It looks like you forgot to select an option above.
              </Ty>
            </Grid2>
          )}

          {/* save button */}
          <Grid2 xs={12}>
            <Box display="flex" justifyContent="center">
              <Button
                color="accentPurple1"
                size="large"
                disabled={loading}
                isLoading={loading}
                onClick={handleSubmit(saveUserPreference)}
              >
                Continue
              </Button>
            </Box>
          </Grid2>
        </Grid2Ct>
      </CardContent>
    </Card>
  );
};
